import React from "react";
import './Loader.scss';

const Loader = () => (
    <div className="page-loader col-lg-12">
        <div className="spinner-border text-warning row loader" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);
export default Loader;
