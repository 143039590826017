import React from "react";
import topSvg from "../../assets/img/announcement/top.svg";
import botSvg from "../../assets/img/announcement/bottom.svg";
import {Link} from "react-router-dom";
import moment from "moment";
import {INewsItem} from "../../saga/mss-news/models";


export const SingleNewsItem: (props: INewsItem) => JSX.Element = (props: INewsItem) => {

    const getNewsType = (tag?: number) => {
        switch (tag) {
            case 1:
                return 'Blog';
            case 2:
                return 'Announcement';
            case 3:
                return 'Press Release'
        }
    }
    const generateLastNews = () => {
        return (
            <>
                {props.lastThreeNews.map((newsItem) => {
                    return (
                        <div key={newsItem.id} className="newsItem col-xl-12">
                            <div className="body">
                                {newsItem?.images &&
                                    <img
                                        src={`data:image/png;base64,${newsItem?.images[0]?.image}`}
                                        alt="Article Title"
                                    />
                                }
                                <div className="category">
                                    {getNewsType(newsItem?.tag)}
                                </div>
                                <h3>
                                    <Link to={`/singleNews/${newsItem?.id}`}>
                                        {newsItem?.title}
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    )
                })
                }
            </>
        )
    }

    return (
        <>
            <div className="headerSpace"/>

            <img src={`data:image/png;base64,${props.image}`} className="main hideDesktop" width="100%"
                 alt="Article Title"/>

            <div className="newsSingleTitle">

                <img src={topSvg} className="top hideDesktop" alt={"topSvg"}/>
                <img src={botSvg} className="bottom hideDesktop" alt={"botSvg"}/>

                <div className="wrap">
                    <div className="date hideDesktop">
                        {moment(props.date).format('L')}
                    </div>

                    <div className="sub">Score Update</div>
                    <h1>{props.title}</h1>
                    <p className="hideDesktop"><strong>{props.subtitle}</strong>
                    </p>
                </div>
            </div>

            <div className="featuredImg hideMobile">
                <div className="wrap">
                    <img src={`data:image/png;base64,${props.image}`} className="main"
                         alt="Article Title"/>
                </div>
                <div className="fill">
                    <img src={topSvg} className="top" alt={"topSvg"}/>
                    <img src={botSvg} className="top" alt={"botSvg"}/>
                </div>
            </div>

            <div className="wrap">
                <div className="row">
                    <div className="col-12 col-xl-12">
                        <div className="articleContents">
                            <div className="date hideMobile">
                                {moment(props.date).format('L')}
                            </div>

                            <p className="hideMobile"><strong>{props.subtitle}</strong></p>
                            <div dangerouslySetInnerHTML={{__html: `${props.body}`}}/>
                        </div>

                        <a href="#" className="backToTop">
                            Back To Top
                        </a>

                    </div>
                    <div className="col-12 col-xl-4 d-flex mb-lg-5 mt-lg-5 hideMobile">
                        {props.lastThreeNews?.length && generateLastNews()}
                    </div>
                </div>
            </div>

            <div className="announcement newsSliderMob hideDesktop">
                <img src={topSvg} className="top"/>
                <div className="wrap">
                    {props.lastThreeNews?.length && generateLastNews()}
                </div>
            </div>
            <div id="returnStatus"/>
        </>
    )
}

