import {defaultState, IResultsInit} from "./models";
import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

export const fixturesReducer = (state: IResultsInit = defaultState, action: any): IResultsInit => {
    switch (action.type) {
        case ActionCreatorsTypes.SET_RESULTS_PAGE:
            return {
                ...state,
                pageData: action.payload.pageData,
                table: action.payload.table,
                fixtures: action.payload.fixtures,
                preSelectedInfo: action.payload.selected,
                year: action.payload.year,
                winnerTitle: action.payload.winnerTitle,
                winnerName: action.payload.winnerName,
                filters: action.payload.filters,
                isLoading: false
            }

        case ActionCreatorsTypes.GET_FILTERED_FIXTURES: {
            return {...state, isLoading: true};
        }

        case ActionCreatorsTypes.SET_FILTERED_FIXTURES: {
            return {...state, pageData: action.payload, isLoading: false};
        }

        case ActionCreatorsTypes.SET_SELECTED_WEEK: {
            return {...state, preSelectedInfo: {...state.preSelectedInfo, week: action.payload}}
        }
        case ActionCreatorsTypes.SET_SELECTED_GROUP: {
            return {...state, preSelectedInfo: {...state.preSelectedInfo, group: action.payload}}
        }

        default:
            return state
    }
}
