import { call, put } from 'redux-saga/effects';
import {takeLatest} from 'typed-redux-saga';
import {ActionCreatorsTypes} from "../../../enums/ActionCreatorsTypes";
import {IGetFilteredFixtures, IResultsPageData} from "../models";
import {fixtureFilterRequest} from "../requests";
import {ActionsCreator} from "../../../redux/actions/actionCreator";

function* tryGetFilteredFixture({payload}: IGetFilteredFixtures) {
    try {
        const resp: IResultsPageData = yield call(fixtureFilterRequest, payload);
        yield put(ActionsCreator.setFilteredFixtures(resp));

    } catch (e: unknown) {
        if (e instanceof Error) {
            console.log('tryGetFilteredFixture:', 'Failed to load fixtures');
        }
    }
}

export default function* fixtureSagas() {
        yield takeLatest(ActionCreatorsTypes.GET_FILTERED_FIXTURES, tryGetFilteredFixture);
}
