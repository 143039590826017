import axios, {AxiosError} from "axios";

const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity',
        'Access-Control-Allow-Origin': '*'
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT
});

const handleError = (error: AxiosError, defaultResult: any = {}) => {
    if(!error.response) {
        return;
    }

    return defaultResult;
};

export { client, handleError };
