import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

export interface IFixtureStats {
    totalScore: number;
    turns100to139: number;
    turns140to179: number;
    turns180: number;
    highestCheckout: number;
    checkouts100plus: number;
    average: number;
    checkoutPercentage: number;
    checkouts: string;
}


interface IPlayer {
    id: number;
    name: string;
}

export default interface IMatchStatistics {
    playersStatistics: {
        players: IPlayer[];
        statistics: IFixtureStats[];
    }
    groupName: string;
    players: IPlayer[];
    statistics: IFixtureStats[];
    sequential?: number;
    seriesName: string;
    startDateTime: string;
}

interface IPreSelectedInfo {
    series: number;
    week: number;
    group: number;
    hasValue: boolean;
}

interface IFilterOption {
    id: number;
    name: string;
}

interface IMssFixturesTableData {
    weekNumber: number;
    seria: string;
    weekWinner: string;
    data: any[];
}

interface IPageData {
    mssFixturesTables: IMssFixturesTableData;
}

interface IFilters {
    series: IFilterOption[];
    weeks: IFilterOption[];
    groups: IFilterOption[];
}

export interface IResultsInitResponse {
    selected: IPreSelectedInfo
    filters: IFilters
    pageData: IPageData
    winnerTitle: string
    winnerName: string
    year: number
    fixtures: IFixture[]
    table: ITableRow[]

}

export interface IResultsInit {
    pageData: IPageData
    isLoading: boolean
    preSelectedInfo: IPreSelectedInfo
    fixtures: IFixture[]
    table: ITableRow[]
    winnerTitle: string
    winnerName: string
    filters:IFilters
    year: number
}

export interface IResultsPageData {
    mssFixturesTables: IFixtureTableData
}

export interface IFixtureTableData {
    weekNumber: number;
    seria: string;
    weekWinner: string | null;
    data: IGroupResult[];
}

export interface IGroupResult {
    weekId: number;
    groupId: number;
    groupName: string;
    isFinal: boolean;
    fixturesDate: string;
    fixturesName: null;
    finalGroup: null;
    lines: ITableRow[];
    fixtures: IFixture[];
}

export interface ITableRow {
    player: IFilterOption;
    p: number;
    w: number;
    l: number;
    pts: number;
    diff: number;
    legs: number;
}

export interface IFixture {
    gameId: number;
    fixture: Date;
    playerHome: string;
    playerAway: string;
    scorePlayerHome: number;
    scorePlayerAway: number;
    groupName?: string;
}

export interface IFilterFixturePayload {
    weekId: number;
    phaseId: number;
    yearId: number;
}


export interface IPhase {
    id: number;
    weeks: IWeek[];
    name: string;
}

export interface IWeek {
    year: 0;
    sequential: number;
    id: number;
    number: number;
    from: Date;
    to: Date;
}


export interface IGetFilteredFixtures {
    type: ActionCreatorsTypes.GET_FILTERED_FIXTURES;
    payload: IFilterFixturePayload;
}

export interface ISetFilteredFixtures {
    type: ActionCreatorsTypes.SET_FILTERED_FIXTURES;
    payload: IResultsPageData;
}

export interface ISetSelectedSeries {
    type: ActionCreatorsTypes.SET_SELECTED_SERIES;
    payload: number;
}

export interface ISetSelectedWeek {
    type: ActionCreatorsTypes.SET_SELECTED_WEEK;
    payload: number;
}

export interface ISetSelectedGroup {
    type: ActionCreatorsTypes.SET_SELECTED_GROUP;
    payload: number;
}

export interface IGetResultsPage {
    type: ActionCreatorsTypes.GET_RESULTS_PAGE;
}

export interface IGetResultByFilter {
    type: ActionCreatorsTypes.GET_RESULTS_BY_FILTER;
    payload:string
}

export interface ISetResultsPage {
    type: ActionCreatorsTypes.SET_RESULTS_PAGE;
    payload: IResultsInitResponse;
}


export const defaultState: IResultsInit = {
    pageData: {
        mssFixturesTables: {
            data: [],
            seria: "",
            weekNumber: 0,
            weekWinner: ""
        }
    },
    filters:{
        series:[],
        groups:[],
        weeks:[]
    },
    table: [],
    fixtures: [],
    preSelectedInfo: {
        series: 0,
        week: 0,
        hasValue:false,
        group:0
    },
    winnerName:"",
    winnerTitle:"",
    year:0,
    isLoading:true
}
