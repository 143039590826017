import {IFixture, IResultsInit, ITableRow} from "../../../saga/fixtures/models";
import {IFixtureLabel} from "../Results";

export const getTypeOfFixture = (fixture: IFixture) => {
    if (!fixture.scorePlayerAway && !fixture.scorePlayerHome) {
        return "fixture pending"
    } else {
        return "fixture"
    }
}

export const formatPlayerScore = (fixture: IFixture, score: number) => {
    if (!fixture.scorePlayerAway && !fixture.scorePlayerHome) {
        return "?"
    } else return score
};


export const filterFinalTablesByGroup = (fixturesTables:IResultsInit,currentTableGroup:number): ITableRow[] => {
    if (fixturesTables?.pageData?.mssFixturesTables?.data) {
        const [group1] = fixturesTables?.pageData?.mssFixturesTables?.data
            .filter((group) => group.groupName === 'FinalGroup1');
        const [group2] = fixturesTables?.pageData?.mssFixturesTables?.data
            .filter((group) => group.groupName === 'FinalGroup2');
        return currentTableGroup === 1 ? group1?.lines : group2?.lines
    }
    return []
}

export const getFixtureLabel = (fixture: IFixture): IFixtureLabel => {
    let label: IFixtureLabel = {} as IFixtureLabel;
    switch (fixture.groupName) {
        case 'FinalGroup1':
            return label = {
                label: 'GROUP 1',
                labelClass: 'col-5 col-sm-3 matchRank'
            }
        case 'FinalGroup2':
            return label = {
                label: 'GROUP 2',
                labelClass: 'col-5 col-sm-3 matchRank'
            }
        case 'SemiFinal':
            return label = {
                label: 'SF',
                labelClass: 'col-5 col-sm-3 matchRankSf'
            }
        case 'PlayOff3dplace':
            return label = {
                label: 'QF',
                labelClass: 'col-5 col-sm-3 matchRankQf'
            }
        case 'Final':
            return label = {
                label: 'FINAL',
                labelClass: 'col-5 col-sm-3 matchRankFinal'
            }
    }
    return label;
}

export const concatFinalFixtures = (fixturesTables:IResultsInit): IFixture[] | [] => {
    const finalFixtures: IFixture[] = [];
    fixturesTables?.pageData?.mssFixturesTables?.data
        .forEach((group) => {
            if (group.isFinal) {
                if (group.groupName === "FinalGroup1" && group.fixtures) {
                    const result = group.fixtures.map((fixture: any) => {
                        return {...fixture, groupName: "FinalGroup1"}
                    })
                    finalFixtures.push(...result)
                }
                if (group.groupName === "FinalGroup2" && group.fixtures) {
                    const result = group.fixtures.map((fixture: any) => {
                        return {...fixture, groupName: "FinalGroup2"}
                    })
                    finalFixtures.push(...result)
                }
                if (group.groupName === "SemiFinal" && group.fixtures) {
                    const result = group.fixtures.map((fixture: any) => {
                        return {...fixture, groupName: "SemiFinal"}
                    })
                    finalFixtures.push(...result)
                }
                if (group.groupName === "PlayOff3dplace" && group.fixtures) {
                    const result = group.fixtures.map((fixture: any) => {
                        return {...fixture, groupName: "PlayOff3dplace"}
                    })
                    finalFixtures.push(...result)
                }
                if (group.groupName === "Final" && group.fixtures) {
                    const result = group.fixtures.map((fixture: any) => {
                        return {...fixture, groupName: "Final"}
                    })
                    finalFixtures.push(...result)
                }
            }
        })
    if (!!finalFixtures.length) {
        return finalFixtures
    } else return []
}