import {IFixture} from "../../../saga/fixtures/models";
import React, {ReactElement} from "react";
import {formatPlayerScore, getFixtureLabel, getTypeOfFixture} from "./helpers";
import moment from "moment";
import {removeNameSeparator} from "../../../helpers/rmNameSeparator";

export const generateFixture = (fixture: IFixture, selectedGroup:number,moveToMatchStatistic:(gameId:number)=>void): ReactElement => {
    if (selectedGroup === 4 && !!fixture.gameId) {
        return (
            <div onClick={() => moveToMatchStatistic(fixture.gameId)} key={fixture.gameId}
                 className='col-12 col-xl-6'>
                <div className={getTypeOfFixture(fixture)}>
                    <div className="title">
                        {moment.utc(fixture.fixture).local().format("HH:mm")}
                    </div>
                    <div className="item">
                        <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerHome)}</span>
                        <span className="name">{removeNameSeparator(fixture.playerHome)}</span>
                    </div>
                    <div className="item">
                        <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerAway)}</span>
                        <span className="name">{removeNameSeparator(fixture.playerAway)}</span>
                    </div>
                    <div className={getFixtureLabel(fixture).labelClass}>
                        <span className="">{getFixtureLabel(fixture).label}</span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div onClick={() => moveToMatchStatistic(fixture.gameId)} key={fixture.gameId}
             className="col-12 col-xl-4">
            <div className={getTypeOfFixture(fixture)}>
                <div className="title">
                    {moment.utc(fixture.fixture).local().format("HH:mm")}
                </div>
                <div className="item">
                    <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerHome)}</span>
                    <span className="name">{removeNameSeparator(fixture.playerHome)}</span>
                </div>
                <div className="item">
                    <span className="score">{formatPlayerScore(fixture, fixture.scorePlayerAway)}</span>
                    <span className="name">{removeNameSeparator(fixture.playerAway)}</span>
                </div>
            </div>
        </div>
    )
}