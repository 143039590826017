import React, {useEffect, useState} from 'react';
import topSvg from '../../assets/img/announcement/top.svg';
import botSvg from '../../assets/img/announcement/bottom.svg';
import '../../assets/css/global.scss';
import {client} from "../../services/api.service";
import HonorWidget from "./HonourWidget";
import Loader from "../../components/Loader/Loader";
import moment from "moment/moment";
interface IWinnerPicture {
    fileContents: string;
    contentType: string;
    fileDownloadName: string;
    lastModified: string | null;
    entityTag: string | null;
    enableRangeProcessing: boolean;
}
export interface IHonor {
    phaseId: number;
    from: string;
    to: string;
    winnerPicture: IWinnerPicture;
    phaseNumber: string;
    phaseWinner: string;
    phase2ndPlace: string;
}

interface IPhase {
    from: string;
    to: string;
    isCurrent: boolean;
}

interface ISchedule {
    title: string;
    phases: IPhase[];
}

export interface IDataHonors {
    honors: IHonor[];
    schedule: ISchedule;
}

const Honours: React.FC = () => {
    const [loader, setLoader] = useState(false);
    const [honorsData, setHonorsData] = useState<IDataHonors>({
        schedule: {
            title: "",
            phases: [],
        },
        honors: []
    });
    const [activeWidgetId, setActiveWidgetId] = useState<number>(0);

    const tryGetHonors = async () => {
        try {
            setLoader(true)
            const honours = await client.get('/api/mss-web/honours-init');
            setHonorsData(honours.data);
            setLoader(false)
        } catch (e: unknown) {
            setLoader(false)
            if (e instanceof Error) {
                console.log('tryGetHonors:', e.message);
            }
        }
    }

    useEffect(() => {
        tryGetHonors();
    }, [])

    const isShowDropDownHandler = (id: number): void => {
        if (id === activeWidgetId) {
            return setActiveWidgetId(0);
        }
        setActiveWidgetId(id);
    }

    const getCurrentActivePhaseDate = () => {
        const activePhase = honorsData.schedule?.phases?.find(phases => phases.isCurrent)
        if (activePhase) {
            return `${moment(activePhase.from).format("DD MMM YYYY")} - ${moment(activePhase.to).format("DD MMM YYYY")}`
        } else {
            return ""
        }
    }

    return (
        <>
            <div className="headerSpace"/>

            <div className="newsTitle">
                <div className="wrap">
                    <h1>Honours Board</h1>
                </div>
            </div>

            <div className="honours">
                <div className="wrap">
                    {loader && <Loader/>}
                    <div
                        className={`row ${(!!(honorsData?.honors.length % 2) && honorsData?.honors.length !== 1) ?
                            'd-flex justify-content-start' : 'd-flex justify-content-center'}`}>
                        {
                            honorsData?.honors.map((honor: IHonor) => {
                                return (
                                    <HonorWidget
                                        key={honor.phaseId}
                                        data={honor}
                                        id={+honor.phaseId}
                                        visibility={
                                            activeWidgetId === +honor.phaseId ? "dropdown-players-show" : "dropdown-players-hidden"
                                        }
                                        visibilityHandler={isShowDropDownHandler}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="seriesSchedule">
                <img src={topSvg} className="top" alt={'topSvg'}/>
                <img src={botSvg} className="bottom hideMobile" alt={"botSvg"}/>
                <div className="wrap">
                    <div className="sub">{honorsData.schedule.title}</div>
                    <h3>CURRENT SERIES<span className="hideMobile"> SCHEDULE </span></h3>
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-3 justify-content-center">
                            <div className="scheduleItem">
                                {getCurrentActivePhaseDate()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="returnStatus"/>
            <script
                src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                crossOrigin="anonymous"
            />
            <script
                src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"
                integrity="sha512-Eak/29OTpb36LLo2r47IpVzPBLXnAMPAVypbSZiZ4Qkf8p/7S/XRG5xp7OKWPPYfJT6metI+IORkR5G8F900+g=="
                crossOrigin="anonymous" referrerPolicy="no-referrer"
            />
            <script
                type="text/javascript"
                src="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"
            />
        </>
    )
}

export default Honours;
