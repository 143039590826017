import {getMssNewsRequest} from "./request";
import {call, put} from 'redux-saga/effects';
import {takeLatest} from "typed-redux-saga";
import {IMssNews} from "./models";
import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";
import {ActionsCreator} from "../../redux/actions/actionCreator";


function* tryGetMssNews() {
    try {
        const news: IMssNews = yield call(getMssNewsRequest);
        yield put(ActionsCreator.setNews(news));
        yield put(ActionsCreator.loaderNews(true));
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            yield put(ActionsCreator.loaderNews(true));
            console.log('tryGetMssNews:', e.message);
        }
    }
}

export default function* mssNewsSagas() {
        yield takeLatest(ActionCreatorsTypes.GET_NEWS, tryGetMssNews);
}
