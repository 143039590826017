import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

export interface INewsFetchData {
    singleNews: IMssNews;
    lastThreeNews: IMssNews[];
}

export interface INewsState {
    news: IMssNews[];
    loaded: boolean;
}

export interface INewsItem {
    title: string;
    subtitle: string;
    id: number;
    body: string;
    image: string;
    date: Date;
    lastThreeNews: IMssNews[];
}

export interface IMssNewsImages {
    id: number;
    newsId: number;
    image: string;
}

export interface IMssNews {
    id: number;
    title: string;
    subtitle: string;
    text: string;
    videoUrl: string;
    tag: number;
    newsDate: Date;
    images: IMssNewsImages[];
}

export interface IRespNews {
    news: IMssNews[];
}
export interface IGetNews {
    type: ActionCreatorsTypes.GET_NEWS;
}
export interface ISetNews {
    type: ActionCreatorsTypes.SET_NEWS;
    payload: IMssNews;
}
export interface INewsLoader {
    type: ActionCreatorsTypes.NEWS_LOADER;
    payload: boolean;
}

export type NewsReducerType = ISetNews | INewsLoader;

export const defaultState: INewsState = {
    news: [
        {
            id: 0,
            title: '',
            subtitle: '',
            text: '',
            videoUrl: '',
            tag: 0,
            newsDate: new Date,
            images: [
                {
                    id: 0,
                    newsId: 0,
                    image: ''
                }
            ]
        }
    ],
    loaded: false
}
