import {client} from "../../services/api.service";
import IFixtures, {IFilterFixturePayload, IResultsInitResponse} from "./models";
import IMatchStatistics from "./models";

export const getFixturesTablesRequest = async (): Promise<IResultsInitResponse> => {
    const results = await client.get(`api/mss-web/results-fixtures`);
    return results.data;
}
export const getFixturesByFilter = async (query: string): Promise<IResultsInitResponse> => {
    const results = await client.get(`api/mss-web/results-fixtures${query}`);
    return results.data;
}
export const getMatchStatistic = async (gameId: number): Promise<IMatchStatistics> => {
    const response = await client.get(`/api/mss-web/fixtures/${gameId}`);
    return response.data;
}

export const fixtureFilterRequest = async (payload: IFilterFixturePayload): Promise<IFixtures> => {
    const response = await client.get(`/api/mss-web/results-data?WeekId=${payload.weekId}&PhaseId=${payload.phaseId}&Year=${payload.yearId}`);
    return response.data;
}
