import { all } from 'redux-saga/effects';
import fixtureTablesSagas from "./fixtures/resultsPage/saga";
import mssNewsSagas from "./mss-news/saga";
import fixtureSagas from "./fixtures/fixture-filtering/saga";

export default function* rootSaga() {
    yield all([
        fixtureSagas(),
        fixtureTablesSagas(),
        mssNewsSagas(),
    ]);
};

