import React from 'react';
import '../../assets/css/global.scss'
import logo from "../../assets/img/logo.svg";
import IGameLogo from "../../assets/img/igm_logo.svg";
import TwitterX from "../../assets/img/twiitterX.png";
import {Link} from "react-router-dom";

const AppFooter = () => {
    return (
        <footer>
                <div className="footer-wrapper">
                    <div className="col-1.5">
                        <img src={logo} className="logo"
                             alt="Modus Super Series"/>
                    </div>
                    <div className="footer-menu col-md-7 col-lg-8 col-xl-7 nav sequel">
                        <Link to={"/results"}>Results & Fixtures</Link>
                        <Link to={"/news"} >Latest News</Link>
                        <Link to={'/about'}>About</Link>
                        <Link to={'/honours'}>Honours</Link>
                        <a href="https://dartshop.tv/darts-tickets/" target="_blank">Tickets</a>

                    </div>
                    <div className="footer-socials col-md-2 col-lg-2 col-xl-3 col-sm 2 socials">
                        <a href="https://igamemedia.com/" target="_blank">
                            <img src={IGameLogo}
                                 className="igameLogo"/>
                        </a>

                        <a href="https://www.facebook.com/MSSdarts" target="_blank">
                            <i className="fab fa-facebook-square"/>
                        </a>

                        <a href="https://twitter.com/MSSdarts" target="_blank">
                            <img src={TwitterX}
                                 className="twitter"/>
                        </a>

                        <a href="https://www.instagram.com/MSSdarts/" target="_blank">
                            <i className="fab fa-instagram"/>
                        </a>
                    </div>
                </div>
        </footer>
    );
};

export default AppFooter;
