import {defaultState, NewsReducerType} from "./models";
import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

export const newsReducer = (state = defaultState, action: NewsReducerType) => {
    switch (action.type) {
        case ActionCreatorsTypes.SET_NEWS:
            return {...state, ...action.payload}
        case ActionCreatorsTypes.NEWS_LOADER:
            return {...state, loaded: action.payload}
        default:
            return state;
    }
}

