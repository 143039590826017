import 'semantic-ui-css/semantic.min.css'
import React from "react";
import {Switch, Route} from "react-router-dom";
import HomePage from "../../pages/HomePage/HomePage";
import Header from "../Header/Header";
import AppFooter from "../AppFooter/AppFooter";
import Results from "../../pages/Results/Results";
import About from "../../pages/About/About";
import AllNews from "../../pages/AllNews/AllNews";
import OneFixture from "../../pages/Results/OneFixture/OneFixture";
import Honours from "../../pages/Honours/Honours";
import NewsSingleOne from "../../pages/NewsSingleOne/NewsSingleOne";



const App = () => {
    return (
        <div>
            <Header/>
            <Switch>
                <Route path="/match/statistics/:id" render={() => <OneFixture/>}/>
                <Route path="/news" render={() => <AllNews/>}/>
                <Route path="/singleNews/:id" render={() => <NewsSingleOne/>}/>
                <Route path="/about" render={() => <About/>}/>
                <Route path="/honours" render={() => <Honours/>}/>
                <Route path="/results" render={() => <Results/>}/>
                <Route path="/" render={() => <HomePage/>}/>
            </Switch>
            <AppFooter/>
        </div>
    );
};

export default App

