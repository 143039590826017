import {call, put,takeEvery} from 'redux-saga/effects';
import {getFixturesByFilter, getFixturesTablesRequest} from "../requests";
import {IGetResultByFilter, IResultsInitResponse} from "../models";
import {ActionCreatorsTypes} from "../../../enums/ActionCreatorsTypes";
import {ActionsCreator} from "../../../redux/actions/actionCreator";


function* tryGetFixturesTables() {
    try {
        const resp: IResultsInitResponse = yield call(getFixturesTablesRequest);
        yield put(ActionsCreator.setResults(resp))

    } catch (e: unknown) {
        if (e instanceof Error) {
            console.log(e.message)
        }
    }
}

function* tryGetFixturesByFilter({payload}: IGetResultByFilter) {
    try {
        const resp: IResultsInitResponse = yield call(getFixturesByFilter,payload);
        yield put(ActionsCreator.setResults(resp))
    } catch (e: unknown) {
        if (e instanceof Error) {
            console.log(e.message)
        }
    }
}

export default function* fixtureTablesSagas():any {
    yield takeEvery(ActionCreatorsTypes.GET_RESULTS_PAGE, tryGetFixturesTables);
    yield takeEvery(ActionCreatorsTypes.GET_RESULTS_BY_FILTER, tryGetFixturesByFilter);
}