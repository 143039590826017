import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import App from "./components/App/App";
import {store} from './redux/configureStore';
import {Provider as ReduxProvider} from 'react-redux';
import {Router} from 'react-router-dom';
import {history} from "./hooks/history";
import './assets/fonts/Bebas Neue Pro Bold Italic.otf';
import './style.scss'

ReactDOM.render(
    <ReduxProvider store={store}>
        <Router history={history}>
            <App/>
        </Router>
    </ReduxProvider>,
    document.getElementById('root')
);

