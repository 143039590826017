import React, {useEffect, useState} from 'react';
import '../../../assets/css/global.scss'
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import {getMatchStatistic} from "../../../saga/fixtures/requests";
import IMatchStatistics from "../../../saga/fixtures/models";
import {splitName} from "../../../helpers/rmNameSeparator";

const OneFixture = () => {
    const {id} = useParams<{ id: string }>()
    const history = useHistory()
    const [statistic, setStatistic] = useState({} as IMatchStatistics);
    const [isLoading, setIsLoading] = useState(false)

    const playersStatistics = statistic?.playersStatistics || {};
    const {players = [], statistics = []} = playersStatistics;
    const {groupName = "", seriesName = "", startDateTime = "", sequential = ""} = statistic;

    useEffect(() => {
        loadPage()
    }, []);

    const backToFixtures = () => history.go(-1)

    const loadPage = () => {
        setIsLoading(true)
        getMatchStatistic(+id).then(async (res) => {
            await setStatistic(res);
            setIsLoading(true);
        });
        return setIsLoading(false)
    }

    const checkIsWinner = (playersTotalScore: number) => {
        const winnersScore = statistics[0].totalScore > statistics[1].totalScore ? statistics[0].totalScore : statistics[1].totalScore
        if (winnersScore === playersTotalScore) {
            return "highlight"
        } else {
            return
        }
    }

    const formatScore = (score: number, isPercentage?: boolean) => {
        const currentScore = score.toFixed(2).toString().split(".")
        return (
            <>
                {currentScore[0]}<small>.{currentScore[1]}{isPercentage ? "%" : ""}</small>
            </>
        )
    }

    const setStartDayMonth = (date: string) => {
        const day = moment.utc(date).local().format('D');
        const month = moment.utc(date).local().format('MMMM');
        return `${month} ${day}`
    }

    const setStartMinHours = (date: string) => {
        const hours = moment.utc(date).local().format('HH');
        const minutes = moment.utc(date).local().format('mm');
        return `${hours}:${minutes}`
    }

    return (
        <div>
            <div className="headerSpace"/>

            <div className="newsTitle">
                <div className="wrap">
                    <h1>Match Stats</h1>
                </div>
            </div>

            {isLoading && Object.keys(statistic).length !== 0 ? <div className="matchStats">
                    <div className="wrap">

                        <div className="row meta hideMobile">
                            <div className="col-12 col-xl-12 right">
                                <a className="tab matchStats-tab">
                                    {setStartDayMonth(startDateTime)} {setStartMinHours(startDateTime)}
                                </a>

                                <a className="tab matchStats-tab">
                                    {seriesName}
                                </a>

                                <a className="tab matchStats-tab">
                                    {groupName}
                                </a>
                            </div>
                        </div>

                        <div className="row meta hideDesktop">
                            <div className="col-12 col-xl-12 right">

                                <p className="mobileDate">
                                    {setStartDayMonth(startDateTime)} {setStartMinHours(startDateTime)}
                                </p>

                                <a href="#" className="tab fixture-tab">
                                    {seriesName}
                                </a>

                                <a href="#" className="tab fixture-tab">
                                    {`WEEK ${sequential}`}
                                </a>

                                <a href="#" className="tab fixture-tab">
                                    {groupName}
                                </a>
                            </div>
                        </div>

                        <div className="row vs">
                            <div
                                className={`col-xl-4 col-lg-5 col-md-5 col-5 name wow fadeInLeft results-name ${checkIsWinner(statistics[0].totalScore)}`}>
                                <div>{splitName(players[0]?.name)[0]}</div>
                                {" "}<em
                                className="hideDesktopInline"><br/></em>
                                <div>{splitName(players[0]?.name)[1]}</div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-2 wow fadeInUp score" data-wow-delay="0.8s">
                                <span className={`${checkIsWinner(statistics[0].totalScore)}`}>{statistics[0].totalScore}</span>
                                <span className={`${checkIsWinner(statistics[1].totalScore)}`}>{statistics[1].totalScore}</span>
                            </div>
                            <div
                                className={`col-xl-4 col-lg-5 col-md-5 col-5 name right wow fadeInRight results-name results-name-right ${checkIsWinner(statistics[1].totalScore)}`}>
                                <div>{splitName(players[1]?.name)[0]}</div>
                                {" "} <em
                                className="hideDesktopInline"><br/></em>
                                <div>{splitName(players[1]?.name)[1]}</div>
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {formatScore(statistics[0].average)}
                            </div>
                            <div className="col-4 label">
                                Average
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {formatScore(statistics[0].average)}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {statistics[0].turns100to139}
                            </div>
                            <div className="col-4 label">
                                100+
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {statistics[1].turns100to139}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {statistics[0].turns140to179}
                            </div>
                            <div className="col-4 label">
                                140+
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {statistics[1].turns140to179}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {statistics[0].turns180}
                            </div>
                            <div className="col-4 label">
                                180s
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {statistics[1].turns180}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {statistics[0].checkouts}
                            </div>
                            <div className="col-4 label">
                                Checkouts
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {statistics[1].checkouts}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {formatScore(statistics[0].checkoutPercentage, true)}
                            </div>
                            <div className="col-4 label">
                                Checkout %
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {formatScore(statistics[1].checkoutPercentage, true)}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {statistics[0].highestCheckout}
                            </div>
                            <div className="col-4 label long">
                                High Checkout
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {statistics[1].highestCheckout}
                            </div>
                        </div>

                        <div className="row statItem wow fadeIn">
                            <div className={`col-4 ${checkIsWinner(statistics[0].totalScore)}`}>
                                {statistics[0].checkouts100plus}
                            </div>
                            <div className="col-4 label long">
                                Ton+ Checkouts
                            </div>
                            <div className={`col-4 right ${checkIsWinner(statistics[1].totalScore)}`}>
                                {statistics[1].checkouts100plus}
                            </div>
                        </div>

                        <div className="row meta md-back-refresh-container">
                            <div className="justify-content-between">
                                <a onClick={backToFixtures} className="back-btn tab highlight">
                                    <i className="fas fa-caret-left hide-icons"/>
                                    Back
                                </a>
                            </div>
                            <div>
                                <a onClick={loadPage} className="refresh-btn tab highlight">
                                    <i className="fa fa-refresh hide-icons" aria-hidden="true"></i>
                                    Refresh
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                : <Loader/>}
        </div>
    );

};

export default OneFixture;
